import { Button, Card, Col, Form, Input, Row, Space, message } from 'antd';
import React, { useEffect, useState } from 'react';

import { Link } from 'gatsby';
import Typography from '../styles/Typography';
import analytics from '../utils/analytics';
import iterativeLogo from '../images/iterative-logo-white-on-black.png';

const LoginPage = ({ login, onLoginSuccess }) => {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        analytics.page("Login");
    }, []);

    const onLogin = async ({ email, password }) => {
        analytics.track("Login - Clicked Login");
        setLoading(true);

        // Simulate a delayed login as if we are making a network request
        setTimeout(() => {
            const loginSuccessful = login(email, password);
            if (loginSuccessful) {
                analytics.track("Login - Login Success", { email });
                analytics.identify(email);
                onLoginSuccess();
            } else {
                analytics.track("Login - Login Failed", { email });
                message.error("Login failed. Please try again");
            }
            setLoading(false);
        }, 1010);
    };

    return (
        <div style={{ height: "100vh", width: "100%" }}>
            <Row style={{ height: "100%" }}>
                <Col
                    xs={24}
                    md={12}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 40,
                    }}
                >
                    <Card
                        style={{
                            maxWidth: 450,
                            width: "100%",
                            boxShadow:
                                "0 15px 35px 0 rgb(60 66 87 / 8%), 0 5px 15px 0 rgb(0 0 0 / 12%)",
                        }}
                    >
                        <Form layout="vertical" hideRequiredMark onFinish={onLogin}>
                            <Form.Item name="email" label="Email" required>
                                <Input size="large" placeholder="jon@gmail.com" />
                            </Form.Item>

                            <Form.Item name="password" label="Password" required>
                                <Input.Password size="large" />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    block
                                    htmlType="submit"
                                    loading={loading}
                                    size="large"
                                    type="primary"
                                >
                                    Login
                                </Button>
                            </Form.Item>
                        </Form>
                        <p>Don't have an account? Reach out to <Link to="mailto:leana@iterative.vc">leana@iterative.vc</Link> if you want to access our demo day website.</p>
                    </Card>
                </Col>
                <Col
                    xs={0}
                    md={12}
                    style={{
                        backgroundColor: "black",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 40,
                    }}
                >
                    <Space direction="vertical">
                        <img
                            src={iterativeLogo}
                            style={{ width: 107, height: "auto", objectFit: "cover" }}
                            alt="iterative-logo"
                        />
                        <Typography.H1 style={{ color: "white" }}>
                            Demo Day Summer 2024
                        </Typography.H1>
                    </Space>
                </Col>
            </Row>
        </div>
    );
};


export default LoginPage
