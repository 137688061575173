import moment from "moment-timezone";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~~~~~~~ Configurable variables ~~~~~~~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
const STICKY_BANNER_CONTENT =
  "😻 Company pitch videos will be added immediately after the Demo Day Virtual Event on 2 Oct at 12 PM SGT.";
const STICKY_BANNER_HEIGHT = 50;
const DEMO_DAY_START_TIME_FRIENDLY = "2024-10-02T09:30"; // Use this start time to force Demo day to "Start"
const DEMO_DAY_VIDEO_EMBARGO_FRIENDLY = "2024-10-02T13:00"; // Use this start time to force Demo day to "Start"
const SINGAPORE_TIMEZONE_UTC_OFFSET = "+08:00"; // + hh:mm
const INTRODUCTION_VIDEO_VIMEO_LINK =
  "https://player.vimeo.com/video/1014765047";

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~~~~~~~~~~~~ Configurable variables ~~~~~~~~~~~~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

const DEMO_DAY_START_TIME = moment(
  `${DEMO_DAY_START_TIME_FRIENDLY}${SINGAPORE_TIMEZONE_UTC_OFFSET}`
);

const DEMO_DAY_VIDEO_EMBARGO = moment(
  `${DEMO_DAY_VIDEO_EMBARGO_FRIENDLY}${SINGAPORE_TIMEZONE_UTC_OFFSET}`
);

export {
  STICKY_BANNER_CONTENT,
  STICKY_BANNER_HEIGHT,
  DEMO_DAY_START_TIME,
  DEMO_DAY_VIDEO_EMBARGO,
  INTRODUCTION_VIDEO_VIMEO_LINK,
};

