const shouldTrack = () => {
  return !window.localStorage.getItem("tracking_opt_out");
};

const track = (name, properties) => {
  if (!window.analytics || !shouldTrack()) {
    return;
  }
  return window.analytics.track(name, properties);
};

const page = (pageName) => {
  if (!window.analytics || !shouldTrack()) {
    return;
  }
  return window.analytics.track_pageview({ page: pageName });
};

const identify = (email) => {
  if (!window.analytics || !shouldTrack()) {
    return;
  }
  window.analytics.identify(email);
  window.analytics.people.set({ $email: email });
};

export default {
  track,
  page,
  identify,
};
