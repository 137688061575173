import { Avatar, Button, Col, Modal, Row, Space, Typography, message, notification } from "antd";
import React, { useState } from "react";

import { DEMO_DAY_VIDEO_EMBARGO } from "../utils/config";
import analytics from "../utils/analytics";
import colors from "../styles/colors";
import moment from "moment-timezone";
import zapier from "../network/zapier";

const CompanyCard = ({
    name,
    oneLiner,
    description,
    pitchVideoUrl,
    staticImageUrl,
    qnaVideoUrl,
    members,
    useStaticImage = false,
}) => {
    const [connectLoading, setConnectLoading] = useState(false);
    const [showLoveLoading, setShowLoveLoading] = useState(false);
    const [openQnAModal, setOpenQnAModal] = useState(false);
    const demoEmbargoLifted = moment().isAfter(DEMO_DAY_VIDEO_EMBARGO);

    return (
        <>
            <Row gutter={[30, 30]}>
                <Col xs={24} xl={24}>
                    <Space direction="vertical" size={12} style={{ width: "100%" }}>
                        {useStaticImage && !demoEmbargoLifted ? (
                            <img src={staticImageUrl} alt={`${name} company`} style={{ objectFit: "cover", width: "100%" }} />
                        ) : (
                            /* Embedded Video */
                            <div style={{ position: "relative", paddingBottom: "56.25%" }}>
                                <iframe
                                    allowFullScreen
                                    src={`${pitchVideoUrl}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                                    width="100%"
                                    height="100%"
                                    style={{ borderRadius: 4, position: "absolute" }}
                                    frameBorder="0"
                                    allow="autoplay; fullscreen"
                                    title={`pitch-${name}`}
                                />
                            </div>
                        )}
                    </Space>
                </Col>
                <Col xs={24} xl={24}>
                    <Space direction="vertical" size={12} style={{ width: "100%" }}>
                        <Typography.Title level={3} style={{ color: colors.gray1 }}>
                            {name}
                        </Typography.Title>
                        <Typography.Paragraph>{oneLiner}</Typography.Paragraph>
                        <Typography.Paragraph
                            style={{ color: colors.secondary, marginBottom: 12 }}
                        >
                            {description}
                        </Typography.Paragraph>

                        <Space direction="vertical" size={12} style={{ marginBottom: 12 }}>
                            {members.map(
                                ({
                                    firstName,
                                    lastName,
                                    position,
                                    linkedInUrl,
                                    headshotUrl,
                                }) => {
                                    const fullName = `${firstName} ${lastName}`;
                                    return (
                                        <div key={fullName}>
                                            <a
                                                href={linkedInUrl}
                                                target="_blank"
                                                rel="noreferrer"
                                                onClick={() => {
                                                    analytics.track("Home - Clicked Founder Link", {
                                                        linkedInUrl,
                                                        fullName,
                                                    });
                                                }}
                                                style={{
                                                    textDecoration: "none",
                                                    fontSize: 16,
                                                    color: colors.primary,
                                                }}
                                            >
                                                <Avatar
                                                    src={headshotUrl}
                                                    size={50}
                                                    shape="circle"
                                                    style={{ marginRight: 12 }}
                                                />
                                                {fullName}
                                            </a>
                                            , {position}
                                        </div>
                                    );
                                }
                            )}
                        </Space>

                        <Row wrap gutter={[12, 12]} style={{ paddingBottom: 50 }}>
                            <Col span="auto">
                                <Button
                                    size="large"
                                    type="primary"
                                    loading={connectLoading}
                                    onClick={async () => {
                                        analytics.track("Home - Clicked Connect", { name });
                                        setConnectLoading(true);
                                        try {
                                            await zapier.connectAboutInvesting(name);
                                            notification.open({
                                                message: "🤝 Connection Made",
                                                description: (
                                                    <Typography.Paragraph>
                                                        An email has been sent to {name} indicating your
                                                        interest to connect about investing. They will reach
                                                        out to you shortly.
                                                    </Typography.Paragraph>
                                                ),
                                                duration: 0,
                                            });
                                        } catch (error) {
                                            console.error("Error connecting:", error);
                                            message.error("Failed to connect. Please try again.");
                                        } finally {
                                            setConnectLoading(false);
                                        }
                                    }}
                                >
                                    Connect About Investing
                                </Button>
                            </Col>
                            <Col span="auto">
                                <Button
                                    size="large"
                                    type="default"
                                    loading={showLoveLoading}
                                    onClick={async () => {
                                        analytics.track("Home - Clicked Show Love", { name });
                                        setShowLoveLoading(true);
                                        try {
                                            await zapier.showLove(name);
                                            notification.open({
                                                message: "❤️ Love Shown",
                                                description: (
                                                    <Typography.Paragraph>
                                                        An email has been sent to {name} showing them your
                                                        love.
                                                    </Typography.Paragraph>
                                                ),
                                                duration: 0,
                                            });
                                        } catch (error) {
                                            console.error("Error showing love:", error);
                                            message.error("Failed to show love. Please try again.");
                                        } finally {
                                            setShowLoveLoading(false);
                                        }
                                    }}
                                >
                                    Show Love
                                </Button>
                            </Col>
                            {/* {demoEmbargoLifted && (
                                <Col span={24}>
                                    <Button
                                        size="large"
                                        type="link"
                                        style={{ padding: 0 }}
                                        onClick={() => {
                                            analytics.track("Home - Clicked Watch Q&A Session");
                                            setOpenQnAModal(true);
                                        }}
                                    >
                                        Watch Q&A Session
                                    </Button>
                                </Col>
                            )} */}
                        </Row>
                    </Space>
                </Col>
            </Row>

            <Modal
                centered
                closable={false}
                visible={openQnAModal}
                onCancel={() => {
                    analytics.track("Home - Closed Q&A Session Modal");
                    setOpenQnAModal(false);
                }}
                footer={null}
                bodyStyle={{
                    height: 500,
                    padding: 0,
                    position: "relative",
                    paddingBottom: "56.25%",
                    backgroundColor: "transparent",
                }}
                width={1010}
            >
                <iframe
                    allowFullScreen
                    title={`qna-${name}`}
                    src={`${qnaVideoUrl}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                    width="100%"
                    height="100%"
                    style={{ position: "absolute" }}
                    frameBorder="0"
                    allow="autoplay; fullscreen"
                />
            </Modal>
        </>
    );
};

export default CompanyCard;
