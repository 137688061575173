import { Col, Row, Space, Typography } from "antd";
import React, { useCallback, useEffect, useState } from "react";

import { DEMO_DAY_START_TIME } from "../utils/config";
import ReactDOM from "react-dom";
import analytics from "../utils/analytics";
import moment from "moment-timezone";

const WaitingView = () => {
    const calculateTimeLeft = () => {
        const now = moment();
        const minutesDiff = Math.abs(now.diff(DEMO_DAY_START_TIME, "minutes"));
        const hoursDiff = minutesDiff / 60;
        const daysDiff = hoursDiff / 24;

        return { minutesDiff, hoursDiff, daysDiff };
    };

    const checkHasStarted = () => moment().isAfter(DEMO_DAY_START_TIME);
    const timer = useCallback(
        () =>
            setTimeout(() => {
                ReactDOM.unstable_batchedUpdates(() => {
                    setTimeDiff(calculateTimeLeft());
                    setHasStarted(checkHasStarted());
                });
            }, 5000),
        []
    );

    const [hasStarted, setHasStarted] = useState(checkHasStarted());
    const [timeDiff, setTimeDiff] = useState(calculateTimeLeft());

    useEffect(() => {
        const timerID = timer();
        return () => clearTimeout(timerID);
    }, [timer]);

    // const { minutesDiff, hoursDiff, daysDiff } = timeDiff;
    return (
        <Row justify="space-between">
            <Col flex="450px">
                <Space direction="vertical" size={24} style={{ marginBottom: 24 }}>
                    <Typography.H2>
                        {hasStarted ? (
                            <>
                                Demo day has{" "}
                                <span style={{ textDecoration: "underline" }}>started</span>,
                                please refresh.
                            </>
                        ) : (
                            "Demo Day hasn't started."
                        )}
                    </Typography.H2>
                    <span>Demo Day will start on Wednesday, 2 October 2024 at 10:00AM (SGT).</span>
                    <span>
                        To find out more about the event and the companies, please see{" "}
                        <a
                            href="https://www.iterative.vc/post/meet-the-summer-2023-batch"
                            target="_blank"
                            rel="noreferrer"
                            onClick={() =>
                                analytics.track("Home - Clicked Find Out More Link")
                            }
                        >
                            Iterative Demo Day Summer 2024
                        </a>
                        . If you have questions, please email{" "}
                        <a href="mailto:leana@iterative.vc">leana@iterative.vc</a>.
                    </span>
                </Space>
            </Col>
        </Row>
    );
};

export default WaitingView
