import styled from "styled-components";
import colors from "./colors";
// import breakpoints from "./breakpoints";

// Standardised font sizes
// All text in the website should be catalogued here for consistency

const H1 = styled.h1`
  font-family: -apple-system, "Helvetica Neue", sans-serif;
  font-weight: 700;
  font-size: 64px;
  line-height: 78.14px;
`;

const H2 = styled.h2`
  font-family: -apple-system, "Helvetica Neue", sans-serif;
  font-size: 32px;
  line-height: 50px;
  font-weight: 700;
`;

const H3 = styled.h3`
  font-family: -apple-system, "Helvetica Neue", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: bold;
`;

const H4 = styled.h4`
  font-family: -apple-system, "Helvetica Neue", sans-serif;
  font-size: 24px;
  line-height: 32px;
  font-weight: 400;
`;

const H5 = styled.h5`
  font-family: -apple-system, "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
`;

const Body1 = styled.div`
  font-family: -apple-system, "Helvetica Neue", sans-serif;
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
`;

const Body2 = styled.div`
  font-family: -apple-system, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
`;

const Body2Sans = styled.div`
  font-family: "Sharp Grotesk Book20", sans-serif;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
`;

const TextLink = styled.a`
  font-size: 18px;
  line-height: 32px;
  font-weight: 400;
  text-decoration: underline;
  color: ${colors.blue};
  cursor: pointer;
`;

const Body1SharpGrotesk = styled.div`
  font-family: -apple-system, "Helvetica Neue", sans-serif;
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
`;

const PreTitle = styled.div`
  font-family: -apple-system, "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 22px;
`;

const Caption = styled.div`
  font-family: -apple-system, "Helvetica Neue", sans-serif;
  font-size: 12px;
  line-height: 20px;
`;

const LargeParagraph = styled.div`
  font-family: -apple-system, "Helvetica Neue", sans-serif;
  font-size: 24px;
  line-height: 38px;

  p {
    margin-bottom: 24px;
  }
`;

export default {
  H1,
  H2,
  H3,
  H4,
  H5,
  LargeParagraph,
  TextLink,
  Body1,
  Body1SharpGrotesk,
  Body2,
  Body2Sans,
  Caption,
  PreTitle,
};
