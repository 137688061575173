import axios from "axios";

const zapierAxios = axios.create({
  headers: { "Content-Type": "text/plain" },
});

const connectAboutInvesting = (targetCompanyName) => {
  const email = window.localStorage.getItem("email");

  return zapierAxios.post(
    "https://hooks.zapier.com/hooks/catch/1184016/ojsrg68/",
    { email, targetCompanyName }
  );
};

const showLove = (targetCompanyName) => {
  const email = window.localStorage.getItem("email");

  return zapierAxios.post(
    "https://hooks.zapier.com/hooks/catch/1184016/ojsramj/",
    { email, targetCompanyName }
  );
};

export default {
  connectAboutInvesting,
  showLove,
};
