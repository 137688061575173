import md5 from "md5";

export const UU_KEY = "email";
export const PP_KEY = "u2";

export const getStimsFromData = (data) => {
  return data.nodes.map((edge) => edge.fields.stim);
};

export const checkIsLoggedInWithStims = (stims) => () => {
  if (typeof window === "undefined") return false;

  const username = window.localStorage.getItem(UU_KEY);
  const password = window.localStorage.getItem(PP_KEY);

  return checkAuth(stims, username, password);
};

export const loginWithStims = (stims) => (username, password) => {
  const success = checkAuth(stims, username, password);

  if (!success) {
    return false;
  }

  window.localStorage.setItem(UU_KEY, username);
  window.localStorage.setItem(PP_KEY, password);
  return true;
};

export const logout = () => {
  window.localStorage.removeItem(UU_KEY);
  window.localStorage.removeItem(PP_KEY);
};

export const checkAuth = (stims, username, password) => {
  const stim = md5(`${username}|${password}`);

  return !!stims.find((s) => s === stim);
};
