import araResearchThumbnail from "../images/s24/ara_thumbnail.png";
import beedThumbnail from "../images/s24/beed_thumbnail.png";
// import capgoThumbnail from "../images/s24/capgo_thumbnail.png";
import carboncopiesThumbnail from "../images/s24/carboncopies_thumbnail.png";
import niyamThumbnail from "../images/s24/niyam_thumbnail.png";
import okapiThumbnail from "../images/s24/okapi_thumbnail.jpeg";
import opilotThumbnail from "../images/s24/opilot_thumbnail.png";
import replyrThumbnail from "../images/s24/replyr_thumbnail.png";
import rosalindThumbnail from "../images/s24/rosalind_thumbnail.png";
import seryeThumbnail from "../images/s24/serye_thumbnail.png";
import smartsolarThumbnail from "../images/s24/smartsolar_thumbnail.png";
import sokratechThumbnail from "../images/s24/sokratech_thumbnail.png";
import thriveaiThumbnail from "../images/s24/thriveai_thumbnail.png";
import yuukiThumbnail from "../images/s24/yuuki_thumbnail.png";

export const summer24Companies = [
    {
        "name": "ARA Research",
        "oneLiner": "B2B2C Platform that automates collections",
        "description": "Ara Research specializes in leveraging artificial intelligence to enhance payment recovery and streamline payment automation processes. We provide businesses with AI-driven tools that optimize payment workflows, improve customer engagement, and ultimately increase financial recovery rates while maintaining a user-friendly experience. Our technology is designed to offer scalable solutions that adapt to various industries' needs for managing payments efficiently",
        "pitchVideoUrl": "https://player.vimeo.com/video/1013182149",
        "useStaticImage": true,
        "staticImageUrl": araResearchThumbnail,
        "qnaVideoUrl": "",
        "members": [
            {
                "firstName": "Koen",
                "lastName": "Munneke",
                "position": "CEO",
                "linkedInUrl": "https://www.linkedin.com/in/kcmunneke/",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/C5103AQFQYSPDYUEZ5w/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1583164820218?e=1732752000&v=beta&t=uILnusm2vG9rwqIsxGTRhgBb31V4oobOAPQBJvpAVjk"
            },
            {
                "firstName": "Aravindan",
                "lastName": "Mahadevan",
                "position": "Technical Co-founder & Principal Engineer",
                "linkedInUrl": "https://www.linkedin.com/in/aravind99",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/D5603AQH-a0frWbjsBQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1718230767035?e=1732752000&v=beta&t=QTssVPd8vMlPOzkI1UDrwumLEadrOi5qStn7RAIEfEU"
            }
        ]
    },
    {
        "name": "Beed",
        "oneLiner": "Document AI Platform for the Next Billion Users",
        "description": "Despite the existence of many billion-dollar companies building intelligent document processing solutions, these solutions struggle to adapt beyond the US and UK markets -- 9 out of 10 businesses globally still process documents manually. Beed makes it super simple for users to get started, by offering a library of 1,000+ pre-built models across all industries, so that even non-technical users could self-onboard in three steps: select template, upload documents, click 'Extract'. The secret lies in how Beed scales the number of templates over a short period of time. This, you do not want to miss. In the past one month alone, Beed has onboarded large enterprises such as Autodesk (NASDAQ: ADSK), IFCA (KLSE: IFCAMSC), and numerous SMEs across different industries.",
        "pitchVideoUrl": "https://player.vimeo.com/video/1013183403",
        "useStaticImage": true,
        "staticImageUrl": beedThumbnail,
        "qnaVideoUrl": "",
        "members": [
            {
                "firstName": "Victor",
                "lastName": "Lee",
                "position": "CEO",
                "linkedInUrl": "https://www.linkedin.com/in/victorsllee/",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/D5603AQHN02q8hXIXiA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1714366113860?e=1732752000&v=beta&t=JG703R1S3cD28p6sk2DEloE45rdBn6_tn8nHRpGKizw"
            },
            {
                "firstName": "Hung",
                "lastName": "Nguyen",
                "position": "CTO",
                "linkedInUrl": "https://www.linkedin.com/in/hungnb/",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/C4E03AQEshlvE8iCKAQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1621165004372?e=1732752000&v=beta&t=icyMsLlyt9WOxiRCBPACMTpm8R-5Ruj_EOrpZPPqaW4"
            }
        ]
    },
    {
        "name": "CarbonCopies AI",
        "oneLiner": "Test websites how your users would",
        "description": "CarbonCopies AI empowers teams to test web applications exactly as their users would. Our zero-code platform understands the nuances of non-deterministic content and behavior-driven testing, delivering insights that traditional test automation tools can't.",
        "pitchVideoUrl": "https://player.vimeo.com/video/1013184274",
        "useStaticImage": true,
        "staticImageUrl": carboncopiesThumbnail,
        "qnaVideoUrl": "",
        "members": [
            {
                "firstName": "Isabella",
                "lastName": "Yamin",
                "position": "CEO",
                "linkedInUrl": "https://www.linkedin.com/in/isabellayamin",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/D5603AQElB0eivfQ84w/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1724061868125?e=1732752000&v=beta&t=0m4MPJXX0r83fbFn9AuxBM6QUTYJBOoqU5cm-QOI2tw"
            },
            {
                "firstName": "Yang",
                "lastName": "Zhengzhi",
                "position": "C(AI)O",
                "linkedInUrl": "https://www.linkedin.com/in/yangzhengzhi",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/C5603AQGn0QCj4WBNUw/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1597512755372?e=1733356800&v=beta&t=SqZ3IsD0FSf6j2wKbr1Qf3UriZ6LZpUAdkb9qwdxt5Y"
            }
        ]
    },
    {
        "name": "Niyam AI",
        "oneLiner": "AI powered EDA tooling for semiconductor and electronics manufacturers.",
        "description": "Niyam leverages AI to automate design adjustments, reduce iteration times, and provide instant, actionable insights tailored to specific applications. Our long-term vision is to build a comprehensive ecosystem of intelligent tools that not only match but surpass existing EDA solutions, offering faster, smarter, and more adaptive design capabilities for the evolving needs of the industry.",
        "pitchVideoUrl": "https://player.vimeo.com/video/1013184524",
        "useStaticImage": true,
        "staticImageUrl": niyamThumbnail,
        "qnaVideoUrl": "",
        "members": [
            {
                "firstName": "Samarth",
                "lastName": "Shyam",
                "position": "CEO",
                "linkedInUrl": "https://www.linkedin.com/in/samarthshyam/",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/D4E03AQEP_oRcf-CptQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1663829756542?e=1732752000&v=beta&t=G9PykzInVY2oATvrsxtvO7ZXF86p4Lc0W9IzTGgaRPQ"
            },
            {
                "firstName": "Agrim",
                "lastName": "Singh",
                "position": "CTO",
                "linkedInUrl": "https://sg.linkedin.com/in/agrims",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/C5603AQGbUd6oxzePGg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1632328137357?e=1732752000&v=beta&t=g4HkcricwSYofkEjsOz-Gz_NAtHIcKHTF4YCWDAB2Uc"
            }
        ]
    },
    {
        "name": "Okapi Solar",
        "oneLiner": "Financing the solar transition in Malaysia and Southeast Asia",
        "description": "Okapi is a Malaysian Solar Financing platform that empowers residential solar installers with zero upfront leasing solutions. Through our app, our partners get access to point-of-sale financing with near-instant approvals. 95% of the 1 million Malaysian households who can save money by switching to solar energy have not done so due to lack of financing options. We are changing the status quo, with 25 companies and 120 salespeople onboarded onto our platform in just 6 months, and monthly sales increasing 500% in 3 months. We make money on the monthly leases over our cost of capital and also make a flat % of financed GMV from our partners.",
        "pitchVideoUrl": "https://player.vimeo.com/video/1013184682",
        "useStaticImage": true,
        "staticImageUrl": okapiThumbnail,
        "qnaVideoUrl": "",
        "members": [
            {
                "firstName": "Lai",
                "lastName": "Zhern Yung",
                "position": "CEO",
                "linkedInUrl": "https://www.linkedin.com/in/zhernyung/",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/D5603AQHuMSopCFQoMw/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1715672813698?e=1732752000&v=beta&t=11eoN0whg1WsorIW3fQokrw55DRiPJcopU9atqhTQIQ"
            },
            {
                "firstName": "Christopher",
                "lastName": "Kwong",
                "position": "COO",
                "linkedInUrl": "https://www.linkedin.com/in/christopher-kwong-96685459?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/D4D03AQF19_9EuZA4ig/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1721058084829?e=1732752000&v=beta&t=YCfyu87TcCPRtf5j6itGW5NgZM7X0C-RQf4skLNOwto"
            }
        ]
    },
    {
        "name": "Opilot",
        "oneLiner": "Opilot adds private, on-device AI assistance to enterprise workflows.",
        "description": "Enterprises want AI, but fear security risks and integration challenges. Opilot solves this by providing private, on-device AI assistance for existing enterprise workflows, regardless of ecosystems used (Microsoft, Google, Atlassian, etc). Our vision is to become the most trusted enterprise AI player, by capturing this massive adoption tailwind in enterprises. Our local-first, secure-by-design approach allows companies to work with AI that's adapted to their unique needs, languages and cultures, and breaks open the tech walled gardens. We have secured two enterprise contracts worth over $300k annually within 3 months of launch.",
        "pitchVideoUrl": "https://player.vimeo.com/video/1013184897",
        "useStaticImage": true,
        "staticImageUrl": opilotThumbnail,
        "qnaVideoUrl": "",
        "members": [
            {
                "firstName": "Julien",
                "lastName": "Lauret",
                "position": "CEO",
                "linkedInUrl": "https://www.linkedin.com/in/julienlauret?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/C5103AQHtvpsVBueXpA/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1549007993390?e=1732752000&v=beta&t=5alocPG8B_Yq4oeI-mtIuhWoS9Qf7i2W-FaXtyaGyjg"
            },
            {
                "firstName": "Angelica",
                "lastName": "Handover",
                "position": "COO",
                "linkedInUrl": "https://www.linkedin.com/in/angelicahandover/",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/C4D03AQHZ-PM9TEbgrQ/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1653186988574?e=1732752000&v=beta&t=UhE4lI7OEmejFSV1ryaJfuW8TFIk55EglYU6mRLAonY"
            }
        ]
    },
    {
        "name": "Replyr",
        "oneLiner": "Turn WhatsApp chats into paying customers",
        "description": "Replyr converts leads to customers on WhatsApp by deploying AI Agents that can dynamically chat with customers while interacting with other business tools. Depending on the conversation, Replyr decides which agents to call in real-time; so we can qualify leads, schedule sales appointments, follow up, accept payments, update CRMs, and more.",
        "pitchVideoUrl": "https://player.vimeo.com/video/1013185012",
        "useStaticImage": true,
        "staticImageUrl": replyrThumbnail,
        "qnaVideoUrl": "",
        "members": [
            {
                "firstName": "Dylan",
                "lastName": "Tan",
                "position": "CEO",
                "linkedInUrl": "https://www.linkedin.com/in/tandylan",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/D5603AQHL8NyB9ELI5Q/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1696951861816?e=1732752000&v=beta&t=llNO3nODpL5VP22AKsOFHb9-PMCrSaQPjvfc8nSrIXg"
            }
        ]
    },
    {
        "name": "Rosalind Dx",
        "oneLiner": "The $100 prenatal test",
        "description": "Rosalind Dx is a diagnostics company making prenatal testing accessible to everyone, everywhere. We give expectant parents peace of mind by screening for prenatal conditions for 10X cheaper and 7X faster. Our proprietary technology streamlines the detection of extra chromosomes, and has just been awarded a major Singapore government grant.",
        "pitchVideoUrl": "https://player.vimeo.com/video/1013185164",
        "useStaticImage": true,
        "staticImageUrl": rosalindThumbnail,
        "qnaVideoUrl": "",
        "members": [
            {
                "firstName": "Ella",
                "lastName": "Fung",
                "position": "CEO",
                "linkedInUrl": "https://www.linkedin.com/in/ellafung/",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/D5603AQGRGV0adqoqsg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1720628476676?e=1732752000&v=beta&t=hCr0ZVkvKmnD0IoaXlwzZgluICnXBMns5pfrqPQ7j5Q"
            }
        ]
    },
    {
        "name": "Sokratech",
        "oneLiner": "No-code workflow builder for fraud prevention",
        "description": "Fraud teams are always behind. Sokratech is a no-code platform that lets fraud teams quickly create, test, and deploy fraud prevention rules within minutes, without engineering support. It integrates with local data in Southeast Asia, giving teams full control and transparency. This helps companies respond faster to fraud and stay ahead of frauds.",
        "pitchVideoUrl": "https://player.vimeo.com/video/1013186188",
        "useStaticImage": true,
        "staticImageUrl": sokratechThumbnail,
        "qnaVideoUrl": "",
        "members": [{
            "firstName": "Brian",
            "lastName": "Lee",
            "position": "CEO",
            "linkedInUrl": "https://www.linkedin.com/in/brian-lee-live/",
            "headshotUrl": "https://media.licdn.com/dms/image/v2/D4E03AQGDS967MmoZ4w/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1694694533247?e=1732752000&v=beta&t=beL2timpvkHxBDcv7c8dZ9gbMWW8UYpZ63tiN0JybJM"
        }, {
            "firstName": "Andy Tanu",
            "lastName": "Ciaputra",
            "position": "CTO",
            "linkedInUrl": "https://www.linkedin.com/in/andy-tanu-ciaputra/",
            "headshotUrl": "https://media.licdn.com/dms/image/v2/C4E03AQFifZ9iY1vWsg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1517876826205?e=1732752000&v=beta&t=lNmu-ryyQ3yK2C5BFHsG1VwTRPiQpaZXOMKM8xtX9M0"
        },
        ]
    },
    {
        "name": "Serye FM",
        "oneLiner": "Southeast Asia's Premier Short-form Audio Platform",
        "description": "Serye FM is a premier audio series platform in the Philippines, offering a diverse collection of captivating short-form audio dramas. We’re building a dedicated platform for serialized audio content where users pay to unlock new episodes daily.",
        "pitchVideoUrl": "https://player.vimeo.com/video/1013185904",
        "useStaticImage": true,
        "staticImageUrl": seryeThumbnail,
        "qnaVideoUrl": "",
        "members": [
            {
                "firstName": "Ivy",
                "lastName": "Barnachea",
                "position": "COO",
                "linkedInUrl": "https://ph.linkedin.com/in/ivy-barnachea",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/C5603AQFfT-_3nbOS0w/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1538980969248?e=1732752000&v=beta&t=YML_8r9J6dS1XZ7PX-3KqQwRliO252BCcJWTW1Laetc"
            },
            {
                "firstName": "Ron",
                "lastName": "Baetiong",
                "position": "CEO",
                "linkedInUrl": "https://ph.linkedin.com/in/ronbaetiong",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/D5603AQHXGZ94mlt5EQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1668563315206?e=1733356800&v=beta&t=BX4aTivX6PXlDzZ41dkUjK04a27YQrumxRPjbX6P3VI"
            }
        ]
    },
    {
        "name": "SmartSolar",
        "oneLiner": "Unlocking solar adoption for Vietnamese SMEs",
        "description": "The government of Vietnam has set an ambitious target for 50% of all buildings to be powered by rooftop solar by 2030. What's missing to achieve that is financing at scale. SmartSolar addresses this financing gap and makes it easy for SMEs to switch to solar. Within just 3 months of launch, SmartSolar has secured multiple commercial as well as industrial deployments for rooftop solar.",
        "pitchVideoUrl": "https://player.vimeo.com/video/1013186063",
        "useStaticImage": true,
        "staticImageUrl": smartsolarThumbnail,
        "qnaVideoUrl": "",
        "members": [
            {
                "firstName": "Kevin",
                "lastName": "Junker",
                "position": "CEO",
                "linkedInUrl": "https://www.linkedin.com/in/kevin-junker/",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/C4E03AQGOaseVg5lNUg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1660633137285?e=1732752000&v=beta&t=Xrv6vtiBPw6bLMC4YSnusobGfc_mWRaOVNqjYrYpc5Q"
            }
        ]
    },
    {
        "name": "ThriveAI",
        "oneLiner": "AI-powered product management",
        "description": "ThriveAI is a purpose-built AI platform for Product Management that automates routine tasks through deep vertical integration, enabling PMs to focus on higher leverage strategic impact. Our vision is to create an AI Product Manager that drives strategy, drafts requirements, and collaborates seamlessly with human and AI teammates—transforming the role of PMs and redefining how products are built and scaled.",
        "pitchVideoUrl": "https://player.vimeo.com/video/1013186333",
        "useStaticImage": true,
        "staticImageUrl": thriveaiThumbnail,
        "qnaVideoUrl": "",
        "members": [
            {
                "firstName": "Ishwar",
                "lastName": "Dhanuka",
                "position": "CEO",
                "linkedInUrl": "https://www.linkedin.com/in/ishwardhanuka/",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/C5103AQEUEJ3-KmzGGg/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1555218637222?e=1732752000&v=beta&t=X1pzOUNpnavnHNj_GeHJJMmDOGhTY6-ULXjcoWRC1dQ"
            },
            {
                "firstName": "Brian",
                "lastName": "Ngo",
                "position": "CTO",
                "linkedInUrl": "https://www.linkedin.com/in/brianngo/",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/C5603AQFVHGnrK2buIw/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1596944059607?e=1732752000&v=beta&t=4nZtGu4Ur-9pR-wVj6cQiUU79x9aicXFlrt4UNFi3Uw"
            },

        ]
    },
    {
        "name": "Yuuki",
        "oneLiner": "AI coach for Enterprises to groom Managers & Hi-Performers",
        "description": "Yuuki is an AI-powered coach that helps enterprises transform their managers into high-impact leaders. Enterprises use Yuuki for personalized leadership coaching tailored to each manager's specific needs, role, goals, and personality—all while aligning with company values. Yuuki also provides HR and L&OD team with actionable insights to elevate leadership development among its managers.",
        "pitchVideoUrl": "https://player.vimeo.com/video/1013186578",
        "useStaticImage": true,
        "staticImageUrl": yuukiThumbnail,
        "qnaVideoUrl": "",
        "members": [
            {
                "firstName": "Nihit",
                "lastName": "Nirmal",
                "position": "CEO",
                "linkedInUrl": "https://www.linkedin.com/in/nihitnirmal/",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/C5603AQG87CuDUToa1Q/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1608173903164?e=1732752000&v=beta&t=DtpmZEo6ZBjgE5pDFqdYD6zH_3JFwV5cZ6WsVu3hLLQ"
            },
            {
                "firstName": "Nitin",
                "lastName": "Dhawan",
                "position": "CTO",
                "linkedInUrl": "https://www.linkedin.com/in/nitzee/",
                "headshotUrl": "https://media.licdn.com/dms/image/v2/C4E03AQHdkHxlMYESpw/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1517710407248?e=1732752000&v=beta&t=CtgBsC1h8fl9FSRxKrKQBKMDwo9T46LTPdjbHQff0Lg"
            }
        ]
    },
    // {
    //     "name": "Capgo",
    //     "oneLiner": "AI spreadsheet that fills itself for your research task",
    //     "description": "CapGo is an AI spreadsheet that fills itself by pulling data from different sources via natural language inputs. Users leverage CapGo to do company research for account based marketing or consulting projects.",
    //     "pitchVideoUrl": "",
    //     "useStaticImage": true,
    //     "staticImageUrl": capgoThumbnail,
    //     "qnaVideoUrl": "",
    //     "members": [{
    //         "firstName": "Yichen",
    //         "lastName": "Guo",
    //         "position": "CEO",
    //         "linkedInUrl": "https://www.linkedin.com/in/yichen-yg-guo/",
    //         "headshotUrl": "https://media.licdn.com/dms/image/v2/D5603AQG96VMTcN5N-Q/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1671364270428?e=1732752000&v=beta&t=baFwPcaejCdBLgM6GwsnLHOc9-Fmoypxg3BgF9e7i44"
    //     }, {
    //         "firstName": "Chen",
    //         "lastName": "Yu",
    //         "position": "Tech Co-founder",
    //         "linkedInUrl": "https://www.linkedin.com/in/chenyuio/",
    //         "headshotUrl": "https://media.licdn.com/dms/image/v2/C5603AQHPIcGBiEmS6A/profile-displayphoto-shrink_800_800/profile-displayphoto-shrink_800_800/0/1604894154816?e=1732752000&v=beta&t=m2S06dVbyjacD9N3Ikn5RbSUKnu9Dvbtu_BZe5tkRp4"
    //     },
    //     ]
    // }
]
