import {
  Button,
  Col,
  Divider,
  Modal,
  Row,
  Space,
  message,
} from "antd";
import {
  DEMO_DAY_START_TIME,
  DEMO_DAY_VIDEO_EMBARGO,
  INTRODUCTION_VIDEO_VIMEO_LINK,
  STICKY_BANNER_CONTENT,
  STICKY_BANNER_HEIGHT
} from "../utils/config";
import {
  PP_KEY,
  UU_KEY,
  checkIsLoggedInWithStims,
  getStimsFromData,
  loginWithStims,
} from "../auth";
import React, { useEffect, useState } from "react";
import { graphql, useStaticQuery } from "gatsby";

import CompanyCard from "../components/CompanyCard";
import Layout from "../components/Layout";
import LoginPage from "../components/LoginPage";
import Typography from "../styles/Typography";
import WaitingView from "../components/WaitingView";
import analytics from "../utils/analytics";
import iterativeLogo from "../images/iterative-logo-white-on-black.png";
import moment from "moment-timezone";
import ss from "seededshuffle";
import { summer24Companies } from "../data/s24";

const batchCompanies = summer24Companies;

const HomePage = () => {
  const { allAirtable } = useStaticQuery(
    graphql`
      query {
        allAirtable {
          nodes {
            fields {
              stim
            }
          }
        }
      }
    `
  );

  const stims = getStimsFromData(allAirtable);
  const login = loginWithStims(stims);
  const checkLogin = checkIsLoggedInWithStims(stims);

  const [email, setEmail] = useState("");
  const [authenticated, setAuthenticated] = useState(false);
  const [openIntroModal, setOpenIntroModal] = useState(false);

  useEffect(() => {
    analytics.page("Home");
    const storedEmail = window?.localStorage.getItem(UU_KEY);
    if (storedEmail) {
      analytics.identify(storedEmail);
      // setEmail(storedEmail);
    }
    setAuthenticated(checkLogin());
  }, [checkLogin]);

  const onLoginSuccess = () => {
    message.success("Welcome to Demo Day Summer 2024");
    setAuthenticated(true);
  };

  const demoHasStarted = moment().isAfter(DEMO_DAY_START_TIME);
  const demoEmbargoLifted = moment().isAfter(DEMO_DAY_VIDEO_EMBARGO);

  const randomSeed = typeof window !== "undefined" ? window.localStorage.getItem(PP_KEY) : "";

  const renderContent = () => {
    if (!authenticated || email === "leana@iterative.vc") {
      return <LoginPage onLoginSuccess={onLoginSuccess} login={login} />;
    }

    return (
      <>
        {renderStickyBanner()}
        {renderHeroSection()}
        {renderMainContent()}
        {renderFooter()}
        {renderIntroModal()}
      </>
    );
  };

  const renderStickyBanner = () => {
    if (!STICKY_BANNER_CONTENT) return null;
    return (
      <Row
        justify="center"
        align="middle"
        style={{
          position: "fixed",
          zIndex: 1,
          height: STICKY_BANNER_HEIGHT,
          backgroundColor: "#000",
          color: "#fff",
          width: "100%",
          boxShadow: "0 15px 35px 0 rgb(60 66 87 / 8%), 0 5px 15px 0 rgb(0 0 0 / 12%)",
          display: "none",
        }}
      >
        <span>{STICKY_BANNER_CONTENT}</span>
      </Row>
    );
  };

  const renderHeroSection = () => (
    <div
      style={{
        minHeight: 355 + STICKY_BANNER_HEIGHT,
        width: "100%",
        backgroundColor: "black",
        paddingTop: STICKY_BANNER_HEIGHT,
      }}
    >
      <section
        style={{
          maxWidth: 1010,
          margin: "auto",
          height: 55,
          padding: "14px 40px",
        }}
      >
        <img
          src={iterativeLogo}
          style={{ width: 107, height: "auto", objectFit: "cover" }}
          alt="iterative-logo"
        />
      </section>

      <section style={{ maxWidth: 1010, margin: "auto", padding: 40 }}>
        <Row
          gutter={[30, 30]}
          justify="space-between"
          align="top"
          style={{ height: "100%" }}
        >
          <Col xs={24} xl={12}>
            <Typography.H1 style={{ color: "white" }}>
              Demo Day Summer 2024
            </Typography.H1>
          </Col>
          {demoHasStarted && (
            <Col xs={24} xl={12}>
              <Typography.Body2
                style={{ color: "white", marginBottom: 25 }}
              >
                The Summer 2024 batch includes 15 exceptional companies, carefully selected from over 1,000 applications. With an acceptance rate of just 1.58%, this marks our most selective cohort to date. Iterative invested in these companies at the start of the program, supporting their growth throughout.
              </Typography.Body2>

              {demoEmbargoLifted && (
                <Button
                  type="link"
                  style={{ padding: 0 }}
                  onClick={() => {
                    analytics.track("Home - Clicked Watch Introduction");
                    setOpenIntroModal(true);
                  }}
                >
                  <span
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bold",
                      fontSize: 16,
                    }}
                  >
                    Watch Introduction
                  </span>
                </Button>
              )}
            </Col>
          )}
        </Row>
      </section>
    </div>
  );

  const renderMainContent = () => (
    <div
      style={{
        maxWidth: 1010,
        margin: "auto",
        marginBottom: 160,
        padding: 40,
        marginTop: 60,
        height: "100%",
        minHeight: "calc(100vh - 565px)",
      }}
    >
      {demoHasStarted ? (
        <>
          {renderHowItWorksSection()}
          {renderCompaniesSection()}
        </>
      ) : (
        <WaitingView />
      )}
    </div>
  );

  const renderHowItWorksSection = () => (
    <section style={{ marginBottom: 50 }}>
      <Typography.H2 style={{ marginBottom: 24 }}>
        How it Works
      </Typography.H2>
      <Row gutter={[30, 30]}>
        <Col xs={24} xl={12}>
          <Space direction="vertical">
            <span style={{ fontWeight: "bold" }}>
              Learning About Companies
            </span>
            <span>
              Each company has recorded a 3 minute recording of
              their pitch that explains the problem they solve,
              their traction and their ask.
            </span>
          </Space>
        </Col>
        <Col xs={24} xl={12}>
          <Space direction="vertical">
            <span style={{ fontWeight: "bold" }}>
              Connecting with Companies
            </span>
            <span>There are 2 ways you can contact companies.</span>
            <ol>
              <li>
                <span style={{ fontWeight: "bold" }}>
                  Connect about Investing
                </span>{" "}
                – Sends the founder an email indicating you're
                interested in talking to them about a potential
                investment.
              </li>
              <li>
                <span style={{ fontWeight: "bold" }}>
                  Show Love
                </span>{" "}
                – Sends the founder an email indicating you simply
                like what they are doing.
              </li>
            </ol>
          </Space>
        </Col>
      </Row>
    </section>
  );

  const renderCompaniesSection = () => (
    <section>
      <Typography.H2 style={{ marginBottom: 50 }}>
        Companies
      </Typography.H2>
      <Space
        direction="vertical"
        size={50}
        style={{ width: "100%" }}
      >
        {renderCompanies()}
      </Space>
    </section>
  );

  const renderCompanies = () => {
    const shuffledCompanies = ss.shuffle(batchCompanies, randomSeed);
    const sokratechIndex = shuffledCompanies.findIndex(company => company.name === "Sokratech");
    if (sokratechIndex !== -1) {
      const sokratech = shuffledCompanies.splice(sokratechIndex, 1)[0];
      shuffledCompanies.unshift(sokratech);
    }
    return shuffledCompanies.map((company, index) => (
      <div key={company.name}>
        <CompanyCard
          name={company.name}
          oneLiner={company.oneLiner}
          description={company.description}
          pitchVideoUrl={company.pitchVideoUrl}
          qnaVideoUrl={company.qnaVideoUrl}
          members={company.members}
          useStaticImage={company.useStaticImage}
          staticImageUrl={company.staticImageUrl}
        />
        {index !== shuffledCompanies.length - 1 && <Divider />}
      </div>
    ));
  };

  const renderFooter = () => (
    <div
      style={{ minHeight: 150, backgroundColor: "black", width: "100%" }}
    >
      <section style={{ maxWidth: 1010, margin: "auto", padding: 40 }}>
        <img
          src={iterativeLogo}
          style={{
            width: 107,
            height: "auto",
            objectFit: "cover",
            marginBottom: 50,
          }}
          alt="iterative-logo"
        />
        <p style={{ color: "#999" }}>
          Thanks to{" "}
          <a
            href="https://www.linkedin.com/in/marx-low/"
            style={{ color: "#999", textDecoration: "underline" }}
          >
            Marx Low
          </a>{" "},{" "}
          <a
            href="https://www.linkedin.com/in/wongshennan/"
            style={{ color: "#999", textDecoration: "underline" }}
          >
            Shen Nan
          </a>{" "}
          and{" "}
          <a
            href="https://www.linkedin.com/in/lerk/"
            style={{ color: "#999", textDecoration: "underline" }}
          >
            En Lerk Law
          </a>{" "}
          for helping with this website.
        </p>
      </section>
    </div>
  );

  const renderIntroModal = () => {
    if (!openIntroModal) return null;
    return (
      <Modal
        centered
        closable={false}
        visible={openIntroModal}
        onCancel={() => {
          analytics.track("Home - Closed Introduction Video Modal");
          setOpenIntroModal(false);
        }}
        footer={null}
        bodyStyle={{
          height: 500,
          padding: 0,
          position: "relative",
          paddingBottom: "56.25%",
          backgroundColor: "transparent",
        }}
        width={1010}
      >
        <iframe
          allowFullScreen
          title="Iterative Demo Day Introduction"
          src={INTRODUCTION_VIDEO_VIMEO_LINK}
          width="100%"
          height="100%"
          style={{ position: "absolute" }}
          frameBorder="0"
          allow="autoplay; fullscreen"
        />
      </Modal>
    );
  };

  return <Layout>{renderContent()}</Layout>;
};

export default HomePage;
